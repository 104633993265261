<template>
  <div class="sparkline">
    <e-chart
      :option="config"
      style="width: 90%; max-width: 90%; min-height: 90%; max-height: 90%"
      :autoresize="true"
    />
  </div>
</template>

<script>
export default {
  name: "Sparkline",
  props: {
    sparklineData: {
      require: true,
    },

    color: {
      default: "default",
    },

    labelColor: {
      default: "default",
    },
  },

  data() {
    return {
      config: {
        xAxis: {
          type: "category",
          show: false,
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          smooth: true,
          splitLine: {
            show: false,
          },

          axisLabel: {
            show: true,
          },
        },

        series: [
          {
            data: [],
            type: "line",
            smooth: true,
          },
        ],
      },
    };
  },

  methods: {
    setData() {
      var result = this.sparklineData.map((x) => x.value);

      if (result.length > 0) {
        let max = Math.max.apply(null, result) + 2;
        let min = Math.min.apply(null, result) - 2;
        this.config.yAxis.min = min;
        this.config.yAxis.max = max;
        this.config.series[0].data = result;
      }

      this.setColor();
    },

    setColor() {
      if (this.color != null && this.color != "default") {
        this.config.series[0].color = this.color;
      } else {
        delete this.config.series[0].color;
      }

      if (this.labelColor != null && this.labelColor != "default") {
        this.config.yAxis.axisLabel.color = this.labelColor;
      } else {
        delete this.config.yAxis.axisLabel.color;
      }
    },
  },

  created() {
    this.setData();
  },

  watch: {
    sparklineData() {
      this.setData();
    },

    color() {
      this.setColor();
    },

    labelColor() {
      this.setColor();
    },
  },
};
</script>

<style scoped>
.sparkline {
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>