export const materialColors = [
  // 600
  "#E53935",
  "#5E35B1",
  "#039BE5",
  "#43A047",
  "#FDD835",
  "#F4511E",
  "#546E7A",
  "#D81B60",
  "#3949AB",
  "#00ACC1",
  "#7CB342",
  "#FFB300",
  "#6D4C41",
  "#8E24AA",
  "#1E88E5",
  "#00897B",
  "#C0CA33",
  "#FB8C00",
  // 900
  "#B71C1C",
  "#311B92",
  "#01579B",
  "#1B5E20",
  "#F57F17",
  "#BF360C",
  "#263238",
  "#880E4F",
  "#1A237E",
  "#006064",
  "#33691E",
  "#FF6F00",
  "#3E2723",
  "#4A148C",
  "#0D47A1",
  "#004D40",
  "#827717",
  "#E65100",
];
