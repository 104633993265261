module.exports = {
  getWeatherIcon: index => {
    switch (index) {
      case 1:
        return "mdi-weather-sunny";
      case 2:
        return "mdi-weather-partly-cloudy";
      case 3:
        return "mdi-weather-partly-cloudy";
      case 4:
        return "mdi-weather-partly-cloudy";
      case 5:
        return "mdi-weather-cloudy";
      case 6:
        return "mdi-weather-cloudy";
      case 7:
        return "mdi-weather-fog";
      case 8:
        return "mdi-weather-rainy";
      case 9:
        return "mdi-weather-rainy";
      case 10:
        return "mdi-weather-pouring";
      case 11:
        return "mdi-weather-lightning-rainy";
      case 12:
        return "mdi-weather-snowy-rainy";
      case 13:
        return "mdi-weather-snowy-rainy";
      case 14:
        return "mdi-weather-snowy-rainy";
      case 15:
        return "mdi-weather-snowy-rainy";
      case 16:
        return "mdi-weather-snowy";
      case 17:
        return "mdi-weather-snowy";
      case 18:
        return "mdi-weather-rainy";
      case 19:
        return "mdi-weather-rainy";
      case 20:
        return "mdi-weather-pouring";
      case 21:
        return "mdi-weather-lightning";
      case 22:
        return "mdi-weather-snowy-rainy";
      case 23:
        return "mdi-weather-snowy-rainy";
      case 24:
        return "mdi-weather-snowy-rainy";
      case 25:
        return "mdi-weather-snowy";
      case 26:
        return "mdi-weather-snowy";
      case 27:
        return "mdi-weather-snowy";

      default:
        return "mdi-weather-sunny";
    }
  }
};
